<script setup lang="ts">
interface Props {
  typeformSinisterUrl: string
  isOwner?: boolean
}
withDefaults(defineProps<Props>(), {
  isOwner: false,
})

const emits = defineEmits<{
  openChatModal: []
}>()

const { routing } = storeToRefs(useRoutingStore())
</script>

<template>
  <YscCard>
    <template #title>
      {{ $t('commons.need_help') }}
    </template>

    <div class="space-y-4 divide-y border-gray-200">
      <div v-if="!isOwner">
        <p>
          {{ $t('components.app_request_actions.help.guest_help') }}
        </p>

        <button
          class="link link-primary mt-3"
          @click="emits('openChatModal')"
        >
          {{ $t('components.app_booking_tips.essentials.contact_owner') }}
        </button>
      </div>

      <div
        v-if="routing"
        class="pt-4"
      >
        <p>
          {{ $t('components.app_request_actions.help.description') }}
        </p>
        <NuxtLink
          :to="isOwner? routing.faq_topic_list_owner : routing.faq_topic_list_guest"
          class="link link-primary mt-3"
          target="_blank"
        >
          {{ $t('components.app_help.link') }}
        </NuxtLink>
      </div>

      <div class="pt-4">
        <p>
          {{ $t('components.app_request_actions.incident.description') }}
        </p>
        <NuxtLink
          :to="typeformSinisterUrl"
          class="btn btn-secondary mt-3"
          target="_blank"
        >
          {{ $t('components.app_request_actions.incident.title') }}
        </NuxtLink>
      </div>
    </div>
  </YscCard>
</template>
